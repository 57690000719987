import { Fragment } from "react";
import Product from "../../../Product";

const ProductSection = ({
  products,
  storeId,
  section,
  promotionalProductCountRef,
  eachTypeProductCount,
  seteachTypeProductCount,
  productLimitCount,
}) => {
  const numbersToWords = {
    0: "zero",
    1: "one",
    2: "two",
    3: "three",
    4: "four",
    5: "five",
    6: "six",
    7: "seven",
    8: "eight",
    9: "nine",
    10: "ten",
  };
  return (
    <div className="relative w-full">
      <div className="mx-auto">
        {/* {products.length !== 1 && ( */}
        <div className="flex justify-between items-center py-[10px] md:py-[20px] overflow-hidden rounded-[8px] bg-primary mb-[26px]">
          <div className="md:pl-[30px] pl-[15px]">
            <div className="flex flex-wrap gap-x-[20px] items-center">
              <span className="material-symbols-outlined mr-[6px] font-thin text-[28px] text-primarytext">
                sell
              </span>
              <div className="text-primarytext text-normal-text font-bold">
                {section === "promotionalProduct"
                  ? "Fixed products included with each order"
                  : section === "bundleProduct"
                  ? `Please select ${productLimitCount > 1 ? "up to" : ""} ${
                      productLimitCount
                      // numbersToWords[productLimitCount]
                    }  ${
                      productLimitCount == 1 ? "product" : "products"
                    } from this group`
                  : `Please select ${productLimitCount > 1 ? "up to" : ""} ${
                      productLimitCount
                      // numbersToWords[productLimitCount]
                    } ${
                      productLimitCount == 1 ? "product" : "products"
                    } from this group`}
              </div>
            </div>
          </div>
        </div>
        {/* )} */}
      </div>

      <ul className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-[15px] mb-8 mt-1">
        {section === "bundleProduct" ? (
          <>
            {products &&
              Array.isArray(products) &&
              products.map((crntBundle, bundleIndex) => (
                <Fragment key={bundleIndex}>
                  {crntBundle?.subRows &&
                    Array.isArray(crntBundle?.subRows) &&
                    crntBundle?.subRows.map((bundleProduct, index) => (
                      <li className="text-center" key={index}>
                        <Product
                          product={bundleProduct}
                          storeId={storeId}
                          productIndex={`bundleProduct-${bundleIndex}-${index}`}
                          section={section}
                          promotionalProductCountRef={
                            promotionalProductCountRef
                          }
                          seteachTypeProductCount={seteachTypeProductCount}
                          eachTypeProductCount={eachTypeProductCount}
                          variableForProductTypeSelecte={`bundle_${
                            bundleIndex + 1
                          }`}
                        />
                      </li>
                    ))}
                </Fragment>
              ))}
          </>
        ) : (
          <>
            {products.map((product, index) => (
              <li className="text-center" key={index}>
                <Product
                  product={product}
                  storeId={storeId}
                  productIndex={index}
                  section={section}
                  promotionalProductCountRef={promotionalProductCountRef}
                  seteachTypeProductCount={seteachTypeProductCount}
                  eachTypeProductCount={eachTypeProductCount}
                  variableForProductTypeSelecte={section}
                />
              </li>
            ))}
          </>
        )}
      </ul>
    </div>
  );
};

export default ProductSection;
